.graph-parent-wrapper {
  width: 100%;
  height: 100%;
  min-width: 1800px;
  min-height: 800px;
}

.search-box-wrapper {
  text-align: left;
}

.search-box-wrapper button{
  margin-left: 15px;
}

.Main-root-140 {
  padding: 20px !important;
  padding-top:40px !important;
}

/* .no-results {
  margin-top: 50%;
  transform: translate(-50%);
} */

svg {
  flex-grow: 1;
  width: 100%;
  height: 100%;
}

text {
  font-weight: 300;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serf;
  font-size: 14px;
  cursor: pointer;
}

.node rect {
  stroke: #333;
  fill: #fff;
  stroke-width: 1.5px;
  cursor: pointer;
}

.edgePath path.path {
  stroke: #333;
  fill: none;
  stroke-width: 1.5px;
}

.arrowhead {
  stroke: blue;
  fill: blue;
  stroke-width: 1.5px;
}